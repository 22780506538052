import Demo1 from '../../static/images/demos/3.png';
import Demo2 from '../../static/images/demos/13.png';
import Demo3 from '../../static/images/demos/1.png';
import Demo4 from '../../static/images/demos/2.png';
import Demo5 from '../../static/images/demos/4.png';
import Demo6 from '../../static/images/demos/5.png';
import Demo7 from '../../static/images/demos/6.png';
import Demo8 from '../../static/images/demos/7.png';
import Demo9 from '../../static/images/demos/8.png';
import Demo10 from '../../static/images/demos/9.png';
import Demo11 from '../../static/images/demos/10.png';
import Demo12 from '../../static/images/demos/11.png';
import Demo13 from '../../static/images/demos/12.png';
import Demo14 from '../../static/images/demos/14.png';
import Demo15 from '../../static/images/demos/15.png';
import Demo16 from '../../static/images/demos/16.png';
import Demo17 from '../../static/images/demos/17.png';
import Demo18 from '../../static/images/demos/18.png';
import Demo19 from '../../static/images/demos/19.png';
import Demo20 from '../../static/images/demos/20.png';
import Demo21 from '../../static/images/demos/21.png';
import Demo22 from '../../static/images/demos/22.png';

export const ProductShowcase = [
  {
    id: 1,
    title: 'Deadline 1',
    thumb_url: Demo4,
    productLink: '/fourteen',
  },
  {
    id: 2,
    title: 'Deadline 2',
    thumb_url: Demo3,
    productLink: '/eleven',
  },
  {
    id: 3,
    title: 'Deadline 3',
    thumb_url: Demo13,
    productLink: '/four',
  },
  {
    id: 4,
    title: 'Deadline 4',
    thumb_url: Demo1,
    productLink: '/thirteen',
  },
  {
    id: 5,
    title: 'Deadline 5',
    thumb_url: Demo5,
    productLink: '/ten',
  },
  {
    id: 6,
    title: 'Deadline 6',
    thumb_url: Demo6,
    productLink: '/seven',
  },
  {
    id: 7,
    title: 'Deadline 7',
    thumb_url: Demo2,
    productLink: '/two',
  },
  {
    id: 8,
    title: 'Deadline 8',
    thumb_url: Demo8,
    productLink: '/nine',
  },
  {
    id: 9,
    title: 'Deadline 9',
    thumb_url: Demo9,
    productLink: '/three',
  },
  {
    id: 10,
    title: 'Deadline 10',
    thumb_url: Demo10,
    productLink: '/six',
  },
  {
    id: 11,
    title: 'Deadline 11',
    thumb_url: Demo11,
    productLink: '/five',
  },
  {
    id: 12,
    title: 'Deadline 12',
    thumb_url: Demo12,
    productLink: '/twelve',
  },
  {
    id: 13,
    title: 'Deadline 13',
    thumb_url: Demo7,
    productLink: '/eight',
  },
  {
    id: 14,
    title: 'Deadline 14',
    thumb_url: Demo14,
    productLink: '/one',
  },
  {
    id: 15,
    title: 'Deadline 15',
    thumb_url: Demo15,
    productLink: '/fifteen',
  },
  {
    id: 16,
    title: 'Deadline 16',
    thumb_url: Demo16,
    productLink: '/sixteen',
  },
  {
    id: 17,
    title: 'Deadline 17',
    thumb_url: Demo17,
    productLink: '/seventeen',
  },

  {
    id: 18,
    title: 'Deadline 18',
    thumb_url: Demo18,
    productLink: '/eighteen',
  },
  {
    id: 19,
    title: 'Deadline 19',
    thumb_url: Demo19,
    productLink: '/nineteen',
  },

  {
    id: 20,
    title: 'Deadline 20',
    thumb_url: Demo20,
    productLink: '/twenty',
  },
  {
    id: 21,
    title: 'Deadline 21',
    thumb_url: Demo21,
    productLink: '/twentyone',
  },

  {
    id: 22,
    title: 'Deadline 22',
    thumb_url: Demo22,
    productLink: '/twentytwo',
  },
];
