import React from 'react';
import { closeModal } from '@redq/reuse-modal';
import { Link } from 'gatsby';
import {
  DemoListWrapper,
  ProductCard,
  ImageWrapper,
  Badge,
} from './demoSwitcher.style';

import { ProductShowcase } from '../data/product-showcase/index';

const DemoList = location => {
  return (
    <DemoListWrapper>
      <h2>Explore All demos</h2>
      <div className="productWrap">
        {ProductShowcase.map(product => (
          <div className="productCol" key={`item_key-${product.id}`}>
            <ProductCard>
              <Link
                to={product.productLink}
                activeClassName="current_demo"
                onClick={closeModal}
              >
                <ImageWrapper>
                  {product.badge && (
                    <Badge className="badge">{product.badge}</Badge>
                  )}
                  <img src={product.thumb_url} alt={`item_key-${product.id}`} />
                </ImageWrapper>
              </Link>
              <h3>{product.title} </h3>
            </ProductCard>
          </div>
        ))}
      </div>
    </DemoListWrapper>
  );
};

export default DemoList;
