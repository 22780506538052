import React from 'react';
import { openModal, closeModal } from '@redq/reuse-modal';
import { Icon } from 'react-icons-kit';
import { ic_clear } from 'react-icons-kit/md/ic_clear';
import Button from '../../components/Button';
import DemoList from './demoList.js';
import DemoSwitcherWrapper from './demoSwitcher.style';
import './modalStyle.css';
// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<Icon icon={ic_clear} />}
  />
);

const DemoSwitcher = ({ colors, ...props }) => {
  // Demo switcher modal handler
  const handleDemoSwitcherModal = () => {
    openModal({
      config: {
        className: 'demo_switcher_modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
        animationFrom: { transform: 'translateX(100px)' }, // react-spring <Spring from={}> props value
        animationTo: { transform: 'translateX(0)' }, //  react-spring <Spring to={}> props value
        transition: {
          mass: 1,
          tension: 180,
          friction: 26,
        },
      },
      component: DemoList,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    });
  };

  return (
    <DemoSwitcherWrapper {...props}>
      <Button onClick={handleDemoSwitcherModal} title="demoTxt" />
    </DemoSwitcherWrapper>
  );
};

export default DemoSwitcher;
