module.exports = {
  title: '',
  legalName: '',
  description: ' ',
  address: {
    city: '',
    region: '',
    country: '',
  },
};
